<template lang="pug">
  div

    CCard
      CCardBody
        // Верхняя стата
        CRow(style="margin-top: 15px; margin-left: auto")
          CCol(sm="4", lg="3")
            CWidgetSimple(header="Выручка" :text="Stat.sum + ' руб'")
          CCol(sm="4", lg="3")
            CWidgetSimple(header="Количество" :text="Stat.sales")

    CCard
      CCardHeader
        h4 История продаж
      CCardBody
        CRow
          CCol(md="12")
            VueGoodTable(
              :columns="itemFields",
              :rows="wbStats",
              :search-options="{enabled: true,placeholder: 'Поиск по таблице',}",
              :pagination-options="{enabled: true,nextLabel: 'Вперед',prevLabel: 'Назад',rowsPerPageLabel: 'На странице',ofLabel: 'из',pageLabel: 'Страница', allLabel: 'Все'}",
              )

</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';

export default {
  name: 'Partner_Stats',
  components: {
    VueGoodTable,
  },
  data: function () {
    return {
      Stat: {
        sum: 0, // Точно подсчитанный оборот
        sales: 0 // Всего заказов
      },
      wbStats: [],
      itemFields: [
        {field: "brand", label: "Брэнд"},
        {field: "category", label: "Категория"},
        {field: "forPay", label: "Заработано"},
        {field: "date", label: "Дата"},
      ],
    }
  },
  mounted() {
    this.update();
  },
  methods: {

    update: function () {

      let that = this;

      window.REST.get('wbstats/stats').then(res=>{
        that.wbStats = res.data;
      })

      window.REST.get('wbstats/stat').then(res=>{
        that.Stat.sum = res.data.sum;
        that.Stat.sales = res.data.sales;
      })

    },

  }
}
</script>